.meeting
	display: block

	&__tabs
		display: block
		text-align: center

	&-tabs
		&__buttons
			border-bottom: 1px #ddd solid
			li
				display: inline-block
				vertical-align: bottom
				margin: 0
				padding: 0
				&::before
					display: none
				button
					margin-bottom: -1px
					padding: 12px 50px 10px 50px
					font-size: 18px
					text-align: center
					color: #e2e2e2
					border: 1px transparent solid
					&.active
						color: darken(#e2e2e2, 12%)
						border-color: #ddd
						border-bottom-color: #fff
					&:not('.disabled'):hover
						color: darken(#e2e2e2, 15%)
		&__content
			.meeting-item
				display: none
				&.active
					display: block


.meeting-form
	width: 100%
	max-width: 450px
	margin: 20px auto
	.fieldset
		display: table
		width: 100%
		margin: 0
		padding: 12px 0
		float: none
		&__label, &__input
			display: table-cell
			vertical-align: top
			float: none
			label, input, select, textarea
				width: 100%
		&__label
			width: 100px
			padding-top: 5px
			text-align: left


.timetable
	display: block
	width: 100%
	margin: 40px 0
	header
		display: block
		width: 100%
		padding: 10px 20px
		background: #ebebeb
		border-top-left-radius: 10px
		border-top-right-radius: 10px
		.timetable-h
			display: table
			width: 100%
			&__col
				display: table-cell
				vertical-align: middle
				width: 50%
				font-size: 14px
				font-weight: 600
				letter-spacing: 1px
				color: #9c9c9c
			.timetable__current-week
				padding: 10px 20px
				width: auto
				white-space: nowrap
			.timetable__prev
				text-align: right
			.timetable__next
				text-align: left
		
			a
				color: inherit
				&:hover
					color: $baseColor
				
	&__table
		width: 100%
		border-left: 1px #ebebeb solid
		border-right: 1px #ebebeb solid
		thead th
			padding: 15px 10px
			text-align: left
			border: 0px
		
		tbody
			tr td
				position: relative
				padding: 7px 10px
				width: 15%
				&:not(:last-child)
					&::after
						content: ""
						position: absolute
						top: 0
						bottom: 0
						right: 0
						margin: auto
						width: 1px
						height: 30px
						background: #e5e5e5
				&:first-child
					width: 50px
					padding: 10px 30px
					font-size: 14px
					text-align: right
					white-space: nowrap
	
		h4
			margin-bottom: 0
			font-family: $baseFont
			font-size: 18px
			font-weight: 700
			color: #000
		p
			line-height: 1
			> span
				font-size: 10px
				font-weight: 300
				letter-spacing: 1px
				color: #bbb
	
	.button
		display: inline-block
		vertical-align: middle
		padding: 3px 10px 2px 10px
		font-size: 12px
		color: #fff
		background: #bababa
		border-radius: 20px
		&:hover
			background: $baseColor




.confirmModal
	display: block
	width: 100%
	height: 100%
	position: fixed
	top: 0
	left: 0
	text-align: center
	color: #a9a9a9
	z-index: 999
	&__overlay
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		margin: auto
		background: rgba(255, 255, 255, 0.8)
		z-index: 10
	&__content
		position: absolute
		top: 50%
		left: 0
		right: 0
		margin: auto
		padding: 50px
		max-width: 700px
		background: #fff
		border-radius: 15px
		box-shadow: 0px 0px 80px -15px #888
		+transform(translateY(-50%))
		z-index: 20
		h2
			margin-bottom: 30px
		.buttons
			margin-top: 40px
		p
			font-size: 18px
			&.timetable-date
				font-size: 24px
				span
					border-bottom: 1px #a9a9a9 solid

		.buttons .button
			margin: 5px
			font-size: 18px
			font-weight: 500
			color: #fff