.news
  display: block
  position: relative
  &__list
    position: relative
  &__article
    display: block
    margin: 10px -1%
    +clearfix
  .article
    &__image
      display: block
      width: 23%
      position: relative
      padding: 2% 0
      background: #000
      margin: 1%
      float: left
      overflow: hidden
      &::after
        content: ""
        display: block
        padding-bottom: 56.25%
      a
        display: block
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        &:hover
          img
            opacity: 0.6
      img
        display: block
        position: absolute
        min-width: 100%
        min-height: 100%
        max-width: none
        left: -50%
        right: -50%
        top: -1%
        bottom: -1%
        margin: auto
        opacity: 1
        backface-visibility: hidden
        -webkit-backface-visibility: hidden
        +transform(translateZ(0))
        +transition(150ms all linear)
    &__content
      display: block
      float: left
      margin: 1%
      width: 73%
    &__title
      display: block
      margin: 0
      padding: 0
      margin-bottom: 5px
      text-transform: none
      color: #333
      a
        color: #fff
        &:hover
          text-decoration: underline
    &__date
      display: block
      font-size: 12px
      text-transform: uppercase
      color: #555
    &__text
      display: block
      *
        font-size: 15px
        line-height: 1.4
    &__more
      display: block
      margin: 5px 0
      text-align: right
      a
        font-size: 13px
        font-weight: 600
        color: #fff
        &:hover
          text-decoration: underline

    &__title ~ .article__text, &__date ~ .article__text
      margin-top: 15px


+media-query-max(760px)
  .news
    .article
      &__image
        width: 33%
      &__content
        width: 63%

+media-query-max(700px)
  .news
    +clearfix
    &__article
      display: block
      position: relative
      width: 48%
      margin: 1%
      float: left
      .article
        &__content
          width: 100%
          margin: 0
        &__image
          display: block
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          background: #000
          a
            background: #000
          img
            opacity: 0.2
        &__title
          display: table
          width: 100%
          min-height: 150px
          margin: 0
          color: #fff
          a
            display: table-cell
            vertical-align: bottom
            padding: 20px
            color: #fff
        &__text
          display: none
        &__date
          display: none
        &__more
          display: none


+media-query-max(600px)
  .news
    &__article
      width: 100%
      margin: 10px 0