*,
*::before,
*::after
  box-sizing: border-box

body, html
  display: block
  height: 100%
  width: 100%
  color: #444
body
  display: block
  position: relative
  height: auto
  height: 100%
  font-family: $baseFont
  color: $fontBaseColor
  line-height: 1.6250em
  *
    margin: 0
    padding: 0
    max-width: none
    max-height: none
    border: 0px
    box-sizing: border-box
    outline: none
    font-family: $baseFont
    text-decoration: none
    color: inherit
p, ul, ol
  min-height: 1.2em
  font-size: 14px
  font-weight: 400
  color: inherit
  line-height: 1.8
p
  min-height: 16px
strong
  font-weight: 600
h1, h2, h3, h4 , h5
  font-family: $secondaryFont
  font-weight: 300
  color: $fontSecondaryColor
  .text &, .post-text &
    font-family: $baseFont
    font-weight: 400
    color: $fontBaseColor
  strong
    font-weight: 400
  a
    font-family: inherit
    color: inherit
    line-height: 1
h1
  font-size: 60px
  line-height: 1.2
h2
  font-size: 50px
  line-height: 1.2
h3
  font-size: 40px
  line-height: 1.4
h4
  font-size: 30px
  line-height: 1.2
h5
  font-size: 24px
  line-height: 1.2
h6
  font-size: 18px
ul, li, a 
  margin: 0
  padding: 0
  border: 0
  list-style: none
  text-decoration: none
  position: relative
a 
  line-height: 1.3
  color: $linkColor
  // text-decoration: underline
  &:hover
    color: lighten($fontSecondaryColor, 10%)
img
  display: block
ul li
  margin-bottom: 5px
  padding-left: 20px
  &::before
    content: "\f061"
    position: absolute
    display: inline-block
    vertical-align: middle
    margin-right: 10px
    top: 0
    left: 0
    width: 8px
    font-family: FontAwesome
    font-size: 10px
    color: $fontSecondaryColor
    line-height: 2.1em
  ul
    padding-top: 10px
    li::before
      content: "\2014" !important
      font-weight: 600
      text-shadow: 0px 1px 0px
      
ol
  counter-reset: list
  > li
    margin-bottom: 5px
    padding-left: 20px
    line-height: 1.4
    +numbered-list
    &:nth-child(10n) ~ li::before,
    &:nth-child(10n)::before
      content: counter(list)
ul, ol
  // padding: 5px 0
  color: lighten($fontBaseColor, 10%)

hr
  display: block
  margin: 30px 0
  height: 1px
  background: #ddd


button
  margin: 0
  padding: 0
  background: transparent

form
  display: block
  margin: 0 -2%
  margin-bottom: 0
  .form-wrapper
    display: block
    +clearfix
  .fieldset
    position: relative
    display: block
    width: 96%
    margin: 5px 2%
    padding: 5px 0
    &--large
      display: block
      width: 96%
      text-align: center
    &--submit
      text-align: right
    &--center
      text-align: center
    &--bg
      margin-bottom: 15px
      background: #f5f5f5
      border: 1px rgba(94, 78, 73, 0.3) solid
      .date__date, .date__time
        position: relative
        width: 50%
        float: left
        padding: 10px
      .date__date::after
        content: "\f073"
        position: absolute
        bottom: 17px
        right: 20px
        font-family: FontAwesome
        z-index: 10
        input
          position: relative
    &__content
      padding: 10px 15px
          
  fieldset + h5
    margin-top: 30px !important
  
  input, select, textarea, .jcf-select
    display: block
    position: relative
    width: 100%
    padding: 1.15em 15px 0.85em 15px
    font-size: 14px
    font-weight: 400
    text-align: left
    color: $fontBaseColor
    line-height: 14px
    background-color: #fff
    background-size: 17px
    background-position: 10px center
    background-repeat: no-repeat
    border: 1px rgba(94, 78, 73, 0.2) solid
    border-radius: 3px
    z-index: 10
    +placeholder
      font-size: 14px
      font-weight: 400
      color: #333
      opacity: 1
      line-height: 1
      +transition(all 200ms linear)
    &[type="checkbox"], &[type="radio"]
      width: auto
      cursor: pointer
    &[type="time"]
      &::-webkit-inner-spin-button, 
      &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

    &.error
      border-color: rgba(255, 0, 0, 0.5)
    // &:active
    //   border-color: transparent
    &:focus
      +placeholder
        opacity: 0
      + label
        top: -15px
        opacity: 1
  .time-range
    display: inline-block
    vertical-align: middle
    width: 255px
    margin-left: 15px

  input, select, textarea
    &.small
      display: inline-block
      width: 20%

  .jcf-select
    display: block
    height: 35px
    margin: 0
    padding: 4px 0
    cursor: pointer
    +clearfix
  label.label--static
    display: block
    position: static
    width: 100%
    padding-bottom: 8px
    opacity: 1
    line-height: 1
  select
    padding: 0
    cursor: pointer
  textarea
    height: 125px
    resize: none
  input
    &[type="submit"]
      display: inline-block
      width: auto
      padding-left: 20px
      padding-right: 20px
      font-size: 14px
      font-weight: 600
      text-align: center
      letter-spacing: 2px
      color: #fff
      line-height: 1.4em
      border: 0px
      border-radius: 3px
      background: $baseColor
      &:hover
        background-color: lighten($baseColor, 5%)
      &:active
        background-color: darken($baseColor, 5%)
  input:-webkit-autofill,
  input:-webkit-autofill:hover,  
  input:-webkit-autofill:focus
    background-color: transparent !important
  
  label
    font-size: 12px
    text-transform: uppercase
    line-height: 1

  .option
    label
      display: inline-block
      vertical-align: middle
      position: static
      padding-left: 3px
      font-size: 12px
      text-transform: uppercase
      letter-spacing: 0
      color: #000
      opacity: 1.2
  
  .form-tile
    padding: 15px
    border: 1px #ddd solid
    p, a, ul, ol
      font-size: 12px
  div.error
    display: none !important

    
.form-message
  display: block
  overflow: hidden
  .send-info
    display: block
    position: relative
    width: 100%
    margin-bottom: 40px
    padding: 30px 20px
    border: 1px $fontSecondaryColor solid
    border-radius: 5px
    font-size: 19px
    text-align: center
    overflow: hidden
    p, a, span
      font-size: 19px
    .close-info
      display: inline-block
      position: absolute
      top: 5px
      right: 5px
      padding: 5px
      font-style: normal
      cursor: pointer


.site-wrapper
  +wrapper($siteWidth)



@media screen and (max-height: 740px)
  #site
    min-height: 740px

+media-query-max($netbook)
  #site
    height: auto
  h1
    font-size: 46px
    line-height: 1
  h2
    font-size: 36px
    line-height: 1.2
  h3
    font-size: 26px
    line-height: 1.2
  h4
    font-size: 20px
  h5
    font-size: 16px
