.site-video
  display: block
  position: relative
  width: 100%
  height: 0
  padding-bottom: 51%
  iframe
    position: absolute
    left: 0
    right: 0
    width: 100%
    max-width: 600px
    height: 100% !important
    margin: auto