.buttons
	position: relative
	display: block
	text-align: center
	a
		text-decoration: none

.button
	position: relative
	display: inline-block
	padding: 12px 22px
	font-size: 14px
	font-weight: 300
	text-decoration: none
	line-height: 1
	white-space: nowrap
	color: inherit
	background: $baseColor
	border: 2px transparent solid
	border-radius: 50px
	+transition(all 300ms linear)

	&--red
		color: #fff
		background: $secondaryColor
		border-color: $secondaryColor
		&:hover
			background: rgba($secondaryColor, 0.2)
	&--blue
		color: #fff !important
		background: $baseColor
		border-color: $baseColor
		&:hover
			background: lighten($baseColor, 10%)
			border-color: lighten($baseColor, 10%)
	&--grey
		color: #000
		background: #ddd
		border-color: #ddd
		&:hover
			background: $fontBaseColor
			border-color: $fontBaseColor

	&--tr
		color: currentColor
		background: transparent !important
		&:hover
			border-color: currentColor


	&--uppercase
		font-size: 12px
		text-transform: uppercase



	.site-col--small &
		color: $baseColor
		// &--tr
		// 	color: currentColor